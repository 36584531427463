import styled, { css } from 'styled-components';

import { Divider } from 'components/Divider';
import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';
import { H5 } from 'components/Text/H5';
import { SearchLoadingSectionLine } from 'modules/search/components/Loading/SearchLoadingSectionLine';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { colors, gutterWidth, maxWidth } from 'theme/theme';
import { LISTING_TYPE_HUMAN_NAME_MAP_COUNTS } from 'utils/constants/general/listingTypeHumanNameMapCounts';
import { LISTING_TYPE_PLURAL_HUMAN_NAME_MAP_COUNTS } from 'utils/constants/general/listingTypePluralHumanNameMapCounts';

const Background = styled.div<{
  $darkMode: boolean;
}>`
  margin-bottom: -4px;

  ${(props) =>
    props.$darkMode &&
    css`
      margin-bottom: -16px;
      background: ${colors.backgroundGrey};
    `}
`;

const Wrapper = styled.div`
  display: none;

  @media all and (width >= 992px) {
    display: block;
    margin: 0 auto;
    max-width: ${maxWidth[3]}px;
    padding: 20px ${gutterWidth}px 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% + 16px);
  min-height: 30px;
  margin: 0 -8px;
  text-align: center;
`;

const ListingCountContainer = styled.div`
  margin: 0 8px;
`;

const StyledLine = styled(SearchLoadingSectionLine)`
  flex: 0 0 auto;
  width: 120px;
  max-width: calc(50% - 16px);
  height: 16px;
  margin: 8px;
`;

type Props = {
  darkMode: boolean;
  listingCounts: Record<string, number> | null | undefined;
};

const types = ['JOB', 'INTERNSHIP', 'VOLOP', 'ORG'] as const;

export function CmsListingCounts({ darkMode, listingCounts }: Props) {
  return (
    <Background $darkMode={darkMode}>
      <Wrapper>
        <Container data-qa-id="listing-counts">
          {listingCounts ? (
            <>
              {types.map((type) => {
                const count = listingCounts[type] || 0;
                return (
                  <ListingCountContainer key={type}>
                    <StyledVersionedRouterLink
                      navLink
                      to={searchGetRoutePathSwitch({
                        lang: CURRENT_LOCALE,
                        filtersByName: { type },
                      })}
                      state={{
                        searchFrom: 'Listing Count Link',
                        filterType: type,
                      }}
                    >
                      <H5 tag="span">
                        {count.toLocaleString(CURRENT_LOCALE)}{' '}
                        {count === 1
                          ? LISTING_TYPE_HUMAN_NAME_MAP_COUNTS[type]
                          : LISTING_TYPE_PLURAL_HUMAN_NAME_MAP_COUNTS[type]}
                      </H5>
                    </StyledVersionedRouterLink>
                  </ListingCountContainer>
                );
              })}
            </>
          ) : (
            <>
              {new Array(types.length).fill(null).map((index) => (
                <StyledLine key={index} />
              ))}
            </>
          )}
        </Container>
        <Divider marginTop={20} marginBottom={0} />
      </Wrapper>
    </Background>
  );
}
